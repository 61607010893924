import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** ¿Qué es? */}
            <div className="container lg:px-0 py-20">
                <Title>El Libro</Title>
                <div className="mt-10">
                    <Text className="text-justify">
                        Et presentem una de les novetats més destacades d'aquest 2021.
                        <br />
                        <br />
                        El llibre de les 500 preguntes imprescindibles per accedir a la Guàrdia
                        Urbana de Barcelona. Un recopilatori preguntes oficials que han aparegut en
                        els exàmens de les convocatòries d'anys anteriors i altres preguntes que
                        considerem bàsiques.
                        <br />
                        <br />
                        Un complement perfecte per profunditzar en el teu procés d'estudi; una
                        manera diferent d'aprendre. Totes les preguntes contenen la resposta
                        correcta i en moltes d'elles hem afegit ampliació d'informació.
                        <br />
                        <br />
                        Trobaràs tots els temes de les bases del temari, inclosos els dos temes nous
                        d'aquest any: l'ordenança relativa a la restricció de la circulació de
                        determinats vehicles a la ciutat de Barcelona i la de medi ambient!
                        <br />
                        <br />
                        Amb la compra del llibre t'habilitem de manera TOTALMENT GRATUÏTA el mòdul
                        de les 500 preguntes en format test per practicar el que has estudiat i
                        posar-te a prova! Un mòdul que podràs fer servir a través de l'aplicació
                        iGuB a qualsevol moment i allà on vulguis.
                        <br />
                        <br />
                        Vols ser agent de la Guàrdia Urbana de Barcelona? Fes-te amb aquest llibre
                        únic i no et quedis enrere!
                    </Text>
                </div>
            </div>

            {/** Cómo adquirirlo */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>. Recuerda
                        que puedes combinar diferentes productos en una misma compra.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
